
.header {
  width: 100%;
  padding: 2%;
  background-color: burlywood;
  color: white;
  text-align: center;
}

.display-board {
  width: 100%;
  background-color: rgb(555, 200, 789);
  padding: 5%;
}

.number {
  color: red;
  font-size: 75px;
  text-align: center;
}

.mrgnbtm {
  margin-top: 20px;
}
